import { PaginationType, ShareSnippet } from "@convin/type/Common";
import {
    AiInsightDataByType,
    AiInsightsResponse,
    CIBarGraphData,
    CIFiltersPayload,
    CILineGraphData,
    ConversationsWithSnippets,
    CustomTrackingPatchResponse,
    CustomTrackingPayload,
    CustomTrackingTab,
    CustomTrackingTabWithPhrase,
    DetailedInsightDataByType,
    InsightDataByType,
    InsightStatistics,
    InsightSummary,
    InsightTopics,
    InsightsPayload,
    InsightsStatsPayload,
    PhraseStatistics,
} from "@convin/type/customerIntelligence";
import {
    isDefined,
    updateDownloadQue,
} from "@convin/utils/helper/common.helper";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { Frequency } from "@convin/type/Analytics";
import { DownloadReport } from "@convin/type/Report";

export const customerIntelligenceApiSlice = createApi({
    reducerPath: "customerIntelligenceApiSlice",
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        "CI_TAB",
        "CI_GRAPH",
        "Insights",
        "InsightDetails",
        "InsightStats",
        "InsightTopics",
    ],
    endpoints: (builder) => ({
        // CUSTOM TRACKING
        getCITabs: builder.query<CustomTrackingTab[], void>({
            query: () => ({
                url: "customer_intelligence/customer_intelligence/tabs/",
                method: "POST",
                body: {},
            }),
            providesTags: [{ type: "CI_TAB", id: "LIST" }],
        }),
        getCITab: builder.query<
            CustomTrackingTabWithPhrase,
            { id: number; payload: CustomTrackingPayload }
        >({
            query: ({ payload, id }) => ({
                url: `customer_intelligence/customer_intelligence/tabs/${id}/`,
                method: "POST",
                body: payload,
            }),
            providesTags: (result, error, { id }) => [{ type: "CI_TAB", id }],
        }),
        getCITabGraph: builder.query<
            CILineGraphData | CIBarGraphData,
            {
                id: number;
                payload: CustomTrackingPayload & {
                    is_line_graph: boolean;
                    freq?: Frequency;
                };
            }
        >({
            query: ({ payload, id }) => ({
                url: `customer_intelligence/customer_intelligence/tabs/${id}/graph/`,
                method: "POST",
                body: payload,
            }),
            providesTags: (result, error, { id }) => [{ type: "CI_GRAPH", id }],
        }),
        updateCITabSequence: builder.mutation<
            CustomTrackingTab[],
            CustomTrackingTab[]
        >({
            query: (payload) => ({
                url: "customer_intelligence/customer_intelligence/tabs/seqno/update/",
                method: "PATCH",
                body: {
                    tab_data: payload.map(({ id, seq_no }) => ({ id, seq_no })),
                },
            }),
            async onQueryStarted(payload, { queryFulfilled, dispatch }) {
                const patchResult = dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITabs",
                        undefined,
                        (draft) => {
                            Object.assign(draft, payload);
                        }
                    )
                );
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        customerIntelligenceApiSlice.util.updateQueryData(
                            "getCITabs",
                            undefined,
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                } catch {
                    patchResult.undo();
                }
            },
        }),
        createCITab: builder.mutation<
            CustomTrackingTab,
            Pick<CustomTrackingTab, "title">
        >({
            query: (payload) => ({
                url: "customer_intelligence/customer_intelligence/tabs/create/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITabs",
                        undefined,
                        (draft) => [
                            data,
                            ...draft.map((tab, index) => ({
                                ...tab,
                                seq_no: index + 1,
                            })),
                        ]
                    )
                );
            },
        }),
        updateCITab: builder.mutation<
            CustomTrackingTab,
            Pick<CustomTrackingTab, "id" | "title">
        >({
            query: ({ id, ...payload }) => ({
                url: `customer_intelligence/customer_intelligence/tabs/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITabs",
                        undefined,
                        (draft) => draft.map((e) => (e.id === +id ? data : e))
                    )
                );
            },
        }),
        deleteCITab: builder.mutation<void, number>({
            query: (id) => ({
                url: `customer_intelligence/customer_intelligence/tabs/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITabs",
                        undefined,
                        (draft) =>
                            draft.map((e) =>
                                e.id === +id ? { ...e, is_deleted: true } : e
                            )
                    )
                );
            },
        }),
        getCIPhraseSnippets: builder.query<
            PaginationType<ConversationsWithSnippets>,
            {
                url: string;
                next?: string;
                payload: CustomTrackingPayload | CIFiltersPayload;
            }
        >({
            query: ({ url, next, payload }) => ({
                url: next || url,
                method: "POST",
                body: payload,
            }),
        }),
        addCITabPhrase: builder.mutation<
            CustomTrackingTabWithPhrase,
            {
                tab: number;
                phrase: string;
                representation: string;
                payload: CustomTrackingPayload;
            }
        >({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            query: ({ payload: _, ...rest }) => ({
                url: "/customer_intelligence/search_phrase/v2/",
                method: "POST",
                body: rest,
            }),
            invalidatesTags: (_, error, arg) =>
                !isDefined(error) ? [{ type: "CI_GRAPH", id: arg.tab }] : [],
            async onQueryStarted(
                { tab, payload },
                { dispatch, queryFulfilled }
            ) {
                const { data } = await queryFulfilled;
                dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITab",
                        { id: tab, payload },
                        (draft) => Object.assign(draft, data)
                    )
                );
            },
        }),
        updateCITabPhrase: builder.mutation<
            CustomTrackingPatchResponse,
            {
                tab: number;
                id: number;
                representation: string;
                payload: CustomTrackingPayload;
            }
        >({
            query: ({ id, representation }) => ({
                url: `/customer_intelligence/search_phrase/${id}/`,
                method: "PATCH",
                body: {
                    representation,
                },
            }),
            invalidatesTags: (result, error, arg) =>
                !isDefined(error) ? [{ type: "CI_GRAPH", id: arg.tab }] : [],
            async onQueryStarted(
                { id, tab, payload },
                { dispatch, queryFulfilled }
            ) {
                const { data } = await queryFulfilled;
                dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITab",
                        { id: tab, payload },
                        (draft) =>
                            Object.assign(draft, {
                                ...draft,
                                search_phrases: draft?.search_phrases?.map(
                                    (e) =>
                                        e.id === id
                                            ? {
                                                  ...e,
                                                  representation:
                                                      data?.representation,
                                              }
                                            : e
                                ),
                            })
                    )
                );
            },
        }),
        removeCITabPhrase: builder.mutation<
            void,
            {
                tab: number;
                id: number;
                payload: CustomTrackingPayload;
            }
        >({
            query: ({ id }) => ({
                url: `/customer_intelligence/search_phrase/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(
                { id, payload, tab },
                { dispatch, queryFulfilled }
            ) {
                const deleteResult = dispatch(
                    customerIntelligenceApiSlice.util.updateQueryData(
                        "getCITab",
                        { payload, id: tab },
                        (draft) => {
                            Object.assign(draft, {
                                ...draft,
                                search_phrases: draft?.search_phrases?.map(
                                    (e) =>
                                        e.id !== id
                                            ? e
                                            : { ...e, is_processed: "deleting" }
                                ),
                            });
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
            invalidatesTags: (_, error, arg) =>
                !isDefined(error) ? [{ type: "CI_GRAPH", id: arg.tab }] : [],
        }),
        sendWordCloud: builder.mutation<
            DownloadReport,
            {
                id: number;
                payload: Omit<CustomTrackingPayload, "exclude">;
            }
        >({
            query: ({ id, payload }) => ({
                url: `/customer_intelligence/search_phrase/${id}/word_cloud/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        sendReport: builder.mutation<
            DownloadReport,
            {
                id: number;
                payload: Omit<CustomTrackingPayload, "exclude"> & {
                    is_tab_level?: boolean;
                };
            }
        >({
            query: ({ id, payload }) => ({
                url: `/customer_intelligence/search_phrase/${id}/download/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        sendTabReport: builder.mutation<
            DownloadReport,
            {
                id: number;
                payload: CustomTrackingPayload;
            }
        >({
            query: ({ id, payload }) => ({
                url: `/customer_intelligence/ci_tab_report/${id}/download/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        getPhraseStatistics: builder.query<
            PhraseStatistics,
            {
                id: number;
                payload: CIFiltersPayload;
            }
        >({
            query: ({ id, payload }) => ({
                url: `/customer_intelligence/search_phrase_stats/${id}/`,
                method: "POST",
                body: payload,
            }),
        }),

        //INSIGHTS BY CONVIN
        getInsightSummary: builder.query<
            InsightSummary,
            InsightsPayload["payload"]
        >({
            query: (payload) => ({
                url: "/customer_intelligence/ci_data/",
                method: "POST",
                body: payload,
            }),
        }),
        getInsightByType: builder.query<InsightDataByType, InsightsPayload>({
            query: ({ type, payload }) => ({
                url: `/customer_intelligence/insight/${type}/`,
                method: "POST",
                body: payload,
            }),
            providesTags: (_, __, arg) => [{ type: "Insights", id: arg.type }],
        }),
        getInsightDetails: builder.query<
            DetailedInsightDataByType,
            InsightsPayload
        >({
            query: ({ type, payload }) => ({
                url: `/customer_intelligence/insight_detail_view/${type}/`,
                method: "POST",
                body: payload,
            }),
            providesTags: (_, __, arg) => [
                { type: "InsightDetails", id: arg.type },
            ],
        }),
        getInsightStats: builder.query<InsightStatistics, InsightsStatsPayload>(
            {
                query: ({ id, payload }) => ({
                    url: `/customer_intelligence/insight_stats/${id}/`,
                    method: "POST",
                    body: payload,
                }),
                providesTags: (_, __, arg) => [
                    { type: "InsightStats", id: arg.id },
                ],
            }
        ),
        getInsightTopics: builder.query<InsightTopics, InsightsStatsPayload>({
            query: ({ id, payload }) => ({
                url: `/customer_intelligence/topics/${id}/`,
                method: "POST",
                body: payload,
            }),
            providesTags: (_, __, arg) => [
                { type: "InsightStats", id: arg.id },
            ],
        }),
        downloadInsightReport: builder.mutation<
            DownloadReport,
            InsightsPayload
        >({
            query: ({ type, payload }) => ({
                url: `/customer_intelligence/ci_insight_report_download/${type}/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        shareSnippet: builder.mutation<void, ShareSnippet>({
            query: (payload) => ({
                url: "/share/share_meeting/",
                method: "POST",
                body: payload,
            }),
        }),
        downloadInsightDumpReport: builder.mutation<
            DownloadReport,
            InsightsStatsPayload
        >({
            query: ({ id, payload }) => ({
                url: `/customer_intelligence/report/insight/${id}/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        // AI Insights
        getAiInsightData: builder.query<AiInsightsResponse, CIFiltersPayload>({
            query: (payload) => ({
                url: "/gpt/ai_insight/",
                method: "POST",
                body: payload,
            }),
        }),
        getAiInsightDataByQuestionId: builder.query<
            AiInsightDataByType,
            { id: number } & CIFiltersPayload
        >({
            query: ({ id, ...payload }) => ({
                url: `/gpt/ai_insight/${id}/`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (res, _, { id }) => {
                return isDefined(res) &&
                    typeof res === "object" &&
                    Object.values(res || {})?.length
                    ? Object.values(res || {})?.[0]?.[id] ?? null
                    : null;
            },
        }),
        getAIInsightStats: builder.query<
            InsightStatistics,
            InsightsStatsPayload
        >({
            query: ({ id, payload }) => ({
                url: `/gpt/ai_insight_stats/${id}/`,
                method: "POST",
                body: payload,
            }),
        }),
        downloadAiInsightReport: builder.mutation<
            DownloadReport,
            { id: number } & CIFiltersPayload
        >({
            query: ({ id, ...payload }) => ({
                url: `gpt/ai_insight_report_download/${id}/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        downloadAiInsightDumpReport: builder.mutation<
            DownloadReport,
            InsightsStatsPayload
        >({
            query: ({ id, payload }) => ({
                url: `gpt/ai_insight_meeting_dump/${id}/`,
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetCITabsQuery,
    useGetCITabQuery,
    useGetCITabGraphQuery,
    useUpdateCITabSequenceMutation,
    useCreateCITabMutation,
    useUpdateCITabMutation,
    useDeleteCITabMutation,
    useGetCIPhraseSnippetsQuery,
    useLazyGetCIPhraseSnippetsQuery,
    useAddCITabPhraseMutation,
    useUpdateCITabPhraseMutation,
    useRemoveCITabPhraseMutation,
    useSendWordCloudMutation,
    useSendReportMutation,
    useSendTabReportMutation,
    useGetPhraseStatisticsQuery,
    useGetInsightSummaryQuery,
    useGetInsightByTypeQuery,
    useGetInsightDetailsQuery,
    useGetInsightStatsQuery,
    useGetInsightTopicsQuery,
    useDownloadInsightReportMutation,
    useShareSnippetMutation,
    useDownloadInsightDumpReportMutation,
    useGetAiInsightDataQuery,
    useGetAiInsightDataByQuestionIdQuery,
    useGetAIInsightStatsQuery,
    useDownloadAiInsightReportMutation,
    useDownloadAiInsightDumpReportMutation,
} = customerIntelligenceApiSlice;

import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AIScore,
    AuditDisputeStatus,
    AuditStateType,
    AuditStatus,
    CustomScoreObject,
    ManualScore,
    ScoreObject,
} from "@convin/type/Audit";
import { AuditSheetProviderValue } from "@convin/modules/components/AuditSheet/context/AuditSheetContextProvider";
import { isDefined, jsonToFormData } from "@convin/utils/helper/common.helper";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import { ConversationDetails } from "@convin/type/Conversation";
import { updateAuditScores } from "@convin/utils/helper/auditSheetHelper";
import { Comment } from "@convin/type/Common";

const getApiPath = (
    dashboard_type: AuditSheetProviderValue["dashboardType"]
): string => {
    return dashboard_type === "account" ? "acc_score" : "score"; // Don't modify this.Be careful.If done check audit in all places is working as expected
};

export const auditSheetApiSlice = createApi({
    reducerPath: "auditSheetApiSlice",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["scoreObjects"],
    endpoints: (builder) => ({
        auidtCalculationVariables: builder.query<
            {
                deductionList: CustomScoreObject[];
                questionListWithTemplateViolation: ScoreObject["question_data"][];
                questionListWithCategoryViolation: ScoreObject["question_data"][];
            },
            { template_id: number }
        >({
            queryFn: () => {
                return {
                    data: {
                        deductionList: [],
                        questionListWithTemplateViolation: [],
                        questionListWithCategoryViolation: [],
                    },
                };
            },
        }),
        getAuditStatus: builder.query<
            AuditStatus<ManualScore | AIScore>,
            {
                status_for: "manual" | "ai";
            } & AuditStateType
        >({
            query: ({ dashboardId, dashboardType, ...payload }) => ({
                url: `/audit/${getApiPath(
                    dashboardType
                )}/status/${dashboardId}/`,
                body: payload,
                method: "POST",
            }),
        }),
        getScoreObjects: builder.query<CustomScoreObject[], AuditStateType>({
            query: ({ dashboardType, dashboardId, ...payload }) => ({
                url: `/audit/${getApiPath(
                    dashboardType
                )}/create_list/${dashboardId}/`,
                method: "GET",
                params: payload,
            }),
            providesTags: ["scoreObjects"],
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(
                        auditSheetApiSlice.endpoints.getAuditStatus.initiate({
                            ...payload,
                            status_for: "manual",
                        })
                    );
                    updateAuditScores(dispatch, data, payload);
                } catch {}
            },
        }),
        createScoreObject: builder.mutation<
            ScoreObject & { message: "Score already exists" },
            {
                payload: Partial<Omit<ScoreObject, "id">>;
            } & AuditStateType
        >({
            query: ({ dashboardType, payload }) => ({
                url: `/audit/${getApiPath(dashboardType)}/create/`,
                body: payload,
                method: "POST",
            }),
        }),
        updateScoreObject: builder.mutation<
            ScoreObject,
            AuditStateType & {
                payload: NonNullable<ScoreObject> & { id: number };
            }
        >({
            query: ({ payload, ...rest }) => ({
                url: `/audit/${getApiPath(rest.dashboardType)}/update/${
                    payload.id
                }/`,
                body: payload,
                method: "PATCH",
            }),
        }),
        uploadScoreObjectMedia: builder.mutation<
            ScoreObject,
            { payload: FormData; scoreId: number } & AuditStateType
        >({
            query: ({ scoreId, payload, dashboardType }) => ({
                url: `/audit/${getApiPath(dashboardType)}/media/${scoreId}/`,
                body: payload,
                method: "PATCH",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
            async onQueryStarted(
                { scoreId: _, payload: __, ...rest },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    const promise = dispatch(
                        auditSheetApiSlice.endpoints.getScoreObjects.initiate(
                            rest
                        )
                    );
                    const { data: list } = await promise;
                    const scoreObjects = [...(list ?? [])];
                    await updateAuditScores(
                        dispatch,
                        scoreObjects.map((e) =>
                            e.id === data.id ? { ...e, ...data } : e
                        ),
                        rest
                    );
                } catch {}
            },
        }),
        deleteScoreObjectMedia: builder.mutation<
            ScoreObject,
            { scoreId: number } & AuditStateType
        >({
            query: ({ scoreId, dashboardType }) => ({
                url: `/audit/${getApiPath(dashboardType)}/media/${scoreId}/`,
                method: "DELETE",
            }),
            async onQueryStarted(
                { scoreId: _, ...rest },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    const promise = dispatch(
                        auditSheetApiSlice.endpoints.getScoreObjects.initiate(
                            rest
                        )
                    );
                    const { data: list } = await promise;
                    const scoreObjects = [...(list ?? [])];
                    await updateAuditScores(
                        dispatch,
                        scoreObjects.map((e) =>
                            e.id === data.id ? { ...e, ...data } : e
                        ),
                        rest
                    );
                } catch {}
            },
        }),
        submitAudit: builder.mutation<
            AuditStatus<ManualScore>,
            AuditStateType & {
                payload: {
                    audit_time?: number;
                    mark_na?: boolean;
                    submit: 1;
                };
            }
        >({
            query: ({
                dashboardId,
                dashboardType,
                payload,
                template_id,
                owner_id,
            }) => ({
                url: `/audit/${getApiPath(
                    dashboardType
                )}/status/${dashboardId}/`,
                body: {
                    ...payload,
                    template_id,
                    owner_id,
                },
                method: "POST",
            }),
            invalidatesTags: ["scoreObjects"],
            async onQueryStarted(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                { payload: _, ...rest },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditSheetApiSlice.util.updateQueryData(
                            "getAuditStatus",
                            { ...rest, status_for: "manual" },
                            (draft) => {
                                Object.assign(draft, {
                                    ...draft,
                                    ...data,
                                    scores: {
                                        ...data.scores,
                                        draft_template_marks_audited:
                                            data.scores.template_marks_audited,
                                        draft_template_score:
                                            data.scores.template_score,
                                    },
                                });
                            }
                        )
                    );
                } catch {}
            },
        }),
        acknowledgeAudit: builder.mutation<
            AuditDisputeStatus,
            AuditStateType & { is_ai_rated?: boolean }
        >({
            query: ({
                template_id,
                owner_id,
                dashboardId,
                dashboardType,
                is_ai_rated,
            }) => ({
                url:
                    dashboardType === "account"
                        ? `/audit/account/${dashboardId}/status/`
                        : `/audit/${dashboardId}/status/`,
                method: "POST",
                body: {
                    status: "acknowledge",
                    template_id,
                    owner_id,
                    ...(isDefined(is_ai_rated) && {
                        is_ai_rated: true,
                    }),
                },
            }),
            async onQueryStarted(
                { is_ai_rated, ...rest },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    if (is_ai_rated) {
                        dispatch(
                            auditSheetApiSlice.util.updateQueryData(
                                "getAuditStatus",
                                { ...rest, status_for: "ai" },
                                (draft) => {
                                    Object.assign(draft, {
                                        ...draft,
                                        acknowledged: true,
                                        audit_status_updated_at:
                                            data.audit_status_updated_at,
                                    });
                                }
                            )
                        );
                    } else
                        dispatch(
                            auditSheetApiSlice.util.updateQueryData(
                                "getAuditStatus",
                                { ...rest, status_for: "manual" },
                                (draft) => {
                                    Object.assign(draft, {
                                        ...draft,
                                        acknowledged: true,
                                        audit_status_updated_at:
                                            data.audit_status_updated_at,
                                    });
                                }
                            )
                        );
                    showToast({
                        ...CreateUpdateToastSettings,
                        message: "Acknowledged Successfully",
                    });
                } catch {}
            },
        }),
        raiseDisputeOnAuditor: builder.mutation<
            AuditDisputeStatus,
            AuditStateType & { note: string; is_ai_rated?: boolean }
        >({
            query: ({
                template_id,
                owner_id,
                dashboardId,
                note,
                dashboardType,
                is_ai_rated,
            }) => ({
                url:
                    dashboardType === "account"
                        ? `/audit/account/${dashboardId}/status/`
                        : `/audit/${dashboardId}/status/`,
                method: "POST",
                body: {
                    status: "dispute",
                    template_id,
                    owner_id,
                    note,
                    is_ai_rated,
                },
            }),
            async onQueryStarted(
                { is_ai_rated, note, ...rest },
                { queryFulfilled, dispatch }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    if (is_ai_rated) {
                        dispatch(
                            auditSheetApiSlice.util.updateQueryData(
                                "getAuditStatus",
                                { ...rest, status_for: "ai" },
                                (draft) => {
                                    Object.assign(draft, {
                                        ...draft,
                                        audit_status: "dispute",
                                        audit_status_updated_at:
                                            data.audit_status_updated_at,
                                    });
                                }
                            )
                        );
                    } else
                        dispatch(
                            auditSheetApiSlice.util.updateQueryData(
                                "getAuditStatus",
                                { ...rest, status_for: "manual" },
                                (draft) => {
                                    Object.assign(draft, {
                                        ...draft,
                                        audit_status: "dispute",
                                        audit_status_updated_at:
                                            data.audit_status_updated_at,
                                    });
                                }
                            )
                        );
                } catch {}
            },
        }),
        getAiAuditedMeetingsForAccount: builder.query<
            Array<
                Pick<
                    ConversationDetails,
                    | "start_time"
                    | "end_time"
                    | "owner"
                    | "meeting_type"
                    | "id"
                    | "title"
                >
            >,
            AuditStateType
        >({
            query: ({ dashboardId, dashboardType: _, ...rest }) => ({
                url: `/account/audited_meeting/list/${dashboardId}/`,
                method: "POST",
                body: rest,
            }),
        }),
        createDisputeComment: builder.mutation<
            unknown,
            AuditStateType & {
                comment: string;
                is_ai_rated?: boolean;
                mentioned_users?: [];
            }
        >({
            query: ({
                template_id,
                owner_id,
                dashboardId,
                comment,
                dashboardType,
                is_ai_rated,
                mentioned_users = [],
            }) => ({
                url:
                    dashboardType === "account"
                        ? `/audit/account_score/create/dispute_comment/${dashboardId}/`
                        : `/audit/meeting_score/create/dispute_comment/${dashboardId}/`,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                body: jsonToFormData({
                    template_id,
                    owner_id,
                    comment,
                    is_ai_rated,
                    mentioned_users,
                    is_meeting: dashboardType !== "account",
                }),
            }),
        }),
        getDisputedComments: builder.query<
            Comment[],
            AuditStateType & { is_ai_rated: boolean }
        >({
            query: ({ dashboardId, dashboardType, ...payload }) => ({
                url: `/audit/list/dispute_comment/${dashboardId}/`,
                body: { ...payload, is_meeting: dashboardType !== "account" },
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetAuditStatusQuery,
    useGetScoreObjectsQuery,
    useLazyGetScoreObjectsQuery,
    useCreateScoreObjectMutation,
    useUpdateScoreObjectMutation,
    useAuidtCalculationVariablesQuery,
    useUploadScoreObjectMediaMutation,
    useDeleteScoreObjectMediaMutation,
    useSubmitAuditMutation,
    useAcknowledgeAuditMutation,
    useRaiseDisputeOnAuditorMutation,
    useGetAiAuditedMeetingsForAccountQuery,
    useCreateDisputeCommentMutation,
    useGetDisputedCommentsQuery,
} = auditSheetApiSlice;

export enum PermissionModules {
    Meeting = "Meeting",
    Statistics = "Statistics",
    Library = "Library",
    Sampling_Manager = "Sampling Manager",
    Recording_Manager = "Recording Manager",
    Alerts = "Alerts",
    Audit_Manager = "Audit Manager",
    User_Manager = "User Manager",
    Role_Manager = "Role Manager",
    Topic_Manager = "Topic Manager",
    Integrations = "Integrations",
    Team_Manager = "Team Manager",
    Call_Type_Manager = "Call Type Manager",
    Call_Tags_Manager = "Call Tags Manager",
    Scheduled_Reports = "Scheduled Reports",
    Billing = "Billing",
    Score_Sense = "Score Sense",
    Reports = "Reports",
    General = "General",
    Coaching = "Coaching",
    QMS = "QMS",
    CI = "Convin Insights",
    Accounts = "Accounts",
    Agent_Assist = "Agent Assist",
    Violation_Manager = "Violation Manager",
    Supervisor_Assist = "Supervisor Assist",
    Gpt_Features = "GPT Features",
}

export enum PermissionTypes {
    edit = "edit",
    view = "view",
    delete = "delete",
}

import {
    defaultScoreThresholds,
    meetingTypes,
} from "@convin/config/default.config";
import {
    IParameterState,
    ParameterStateType,
} from "@convin/modules/settings/modules/auditManager/components/context/ParameterStateContext";
import {
    AIScore,
    AuditStatus,
    ManualScore,
    Question,
} from "@convin/type/Audit";
import {
    DomainConfig,
    MeetingTypeConst,
    NumericRange,
} from "@convin/type/Common";
import { UserType } from "@convin/type/User";
import { Team, SubTeam } from "@convin/type/Team";
import { CIFiltersPayload } from "@convin/type/customerIntelligence";
import domtoimage from "dom-to-image";
import {
    ConversationDetails,
    SentimentTranscript,
    Transcript,
} from "@convin/type/Conversation";
import { FieldType, SearchFilter } from "@convin/type/Search";
import { NotificationSettings } from "@convin/config/dashboard.config";
import isEqual from "lodash.isequal";
import { ConversationScoreType } from "@convin/type/Filters.model";
import { DownloadReport } from "@convin/type/Report";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { showToast } from "../toast";
import { analyticsDashboardApiSlice } from "@convin/redux/services/home/analyticsDashboard.service";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import { QmsField } from "@convin/modules/settings/modules/qmsManager/context/QmsStateContext";
import apiConfigs from "@apis/common/commonApiConfig";
import { IConversationDetails } from "@convin/modules/conversationDetails/context/ConversationDetailsProvider";
import { Theme } from "@mui/material";

function pad(d: number) {
    return d < 10 ? "0" + d.toString() : d.toString();
}

export const getUserName = (user: UserType | null | undefined): string => {
    if (user === null || user === undefined) return "";
    const { first_name, last_name, email, id } = user;
    return first_name && last_name
        ? `${first_name} ${last_name}`
        : first_name || email || id?.toString() || "-";
};

export const getQuestionType = (
    type: Question["question_type"]
): "Yes/No" | "Scale 1-10" | "Custom" =>
    type === "yes_no" ? "Yes/No" : type === "rating" ? "Scale 1-10" : "Custom";

export const getColorFromString = (name = ""): string => {
    const colors = [
        "#C83E4D",
        "#FF6B35",

        "#FE5F55",

        "#F564A9",

        "#6622CC",
        "#E27396",
        "#F98948",
        "#9B8816",
        "#AF1B3F",
        "#EC4E20",
        "#E34A6F",
        "#028090",
        "#F9E900",
        "#7F2982",
        "#7F2982",
        "#C792DF",
        "#FF312E",
        "#FF66B3",
        "#F7DD72",
        "#F5B700",
        "#F9E900",
        "#DC0073",

        "#89FC00",
        "#00BD9D",
        "#C287E8",
        "#FCBA04",

        "#613DC1",
        "#4E148C",
        "#63C132",
        "#54428E",
        "#F8C537",
        "#7D8CC4",
        "#EB5160",
        "#3F84E5",
        "#008BF8",
        "#26D1F6",
        "#4D5DEE",
        "#3A70FD",
        "#1A8FE3",
    ];

    function getRandomInteger(): number {
        let integer = 0;
        for (let i = 0; i < name?.length; i += 1) {
            integer += name.charCodeAt(i);
        }
        return (
            // eslint-disable-next-line no-unsafe-optional-chaining
            (integer * name?.charCodeAt(0) || Math.random() * 100) %
            colors.length
        );
    }

    return colors?.[getRandomInteger()] || "#FF7D51";
};

export function capitalizeFirstLetter(val: string | null = ""): string {
    if (!isDefined(val)) return "";
    const str = val?.toLowerCase();
    return str?.charAt(0)?.toUpperCase() + str.slice(1);
}

export function isDefined<T>(value: T | undefined | null): value is T {
    return <T>value !== undefined && <T>value !== null;
}

export function getQuetionTypeLabel(
    value: Question["question_type"] | undefined
): string {
    switch (value) {
        case "yes_no":
            return "Yes or No";
        case "rating":
            return "Scale 1-10";
        case "custom":
            return "Custom";
        default:
            return "None";
    }
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const downloadImage = (blob: string, fileName: string): void => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style.display = "none";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};
export const getTimeZone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export function dataURIToBlob(dataURI: string): Blob {
    dataURI = dataURI.replace(/^data:/, "");

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const base64 = dataURI.replace(/^[^,]+,/, "");
    const arrayBuffer = new ArrayBuffer(base64.length);
    const typedArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < base64.length; i++) {
        typedArray[i] = base64.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
}

export const getDuration = function (start: string, end: string) {
    // get total seconds between the times
    let delta =
        Math.abs(new Date(end).getTime() - new Date(start).getTime()) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = Math.round(delta % 60);

    if (days > 0) {
        return `${pad(days)}d, ${hours}:${pad(minutes)}:${pad(seconds)}`;
    }
    if (hours) {
        return `${pad(hours)}:${pad(minutes)}:${pad(Math.ceil(seconds))}`;
    }
    return `${pad(minutes)}:${pad(Math.ceil(seconds))}`;
};

export const getFileType = (
    fileName: string | null
): "audio" | "video" | "document" => {
    if (fileName === null) return "document";
    const audio = {
        mp3: "mp3",
        ogg: "ogg",
        amr: "amr",
        au: "au",
        awb: "awb",
        flac: "flac",
        mid: "mid",
        wav: "wav",
        wma: "wma",
        mka: "mka",
        webm: "webm",
        acc: "acc",
        ac3: "ac3",
        aiff: "aiff",
        "3gpp": "3gpp",
        smf: "smf",
        aac: "aac",
        "amr-wb": "amr-wb",
        "x-amr-wb": "x-amr-wb",
    };
    const video = {
        mp4: "mp4",
        wav: "wav",
        mov: "mov",
        wmv: "wmv",
        avi: "avi",
        flv: "flv",
        mpg: "mpg",
        mpeg: "mpeg",
        avchd: "avchd",
        f4v: "f4v",
        swf: "swf",
        mkv: "mkv",
        webm: "webm",
        m4a: "m4a",
    };

    const extension = fileName?.split?.(".")?.at?.(-1) as
        | keyof typeof audio
        | keyof typeof video
        | undefined;

    if (!extension) return "document";

    if (extension in audio) return "audio";
    else if (extension in video) return "video";
    return "document";
};

export const downloadFileFromUrl = (fileName: string, url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export function replaceYouTubeWatchWithEmbed(url: string): string {
    // Regular expression to match YouTube watch URLs
    const watchRegex = /https:\/\/www\.youtube\.com\/watch\?v=/;

    // Check if the URL matches the watchRegex
    if (watchRegex.test(url)) {
        // Replace "watch" with "embed"
        const embedUrl = url.replace(
            watchRegex,
            "https://www.youtube.com/embed/"
        );
        return embedUrl;
    }

    // If the URL is not a YouTube watch URL, return the original URL
    return url;
}

export function getFileNameAndExtension(filePath: string): {
    fileName: string;
    fileExtension?: string;
} {
    // Get the last portion of the path after the last '/'
    const fileNameWithExtension: string = filePath.substring(
        filePath.lastIndexOf("/") + 1
    );

    // Check if there is a dot in the file name
    const dotIndex: number = fileNameWithExtension.lastIndexOf(".");

    if (dotIndex !== -1) {
        // Get the file name without extension
        const fileName: string = fileNameWithExtension.substring(0, dotIndex);

        // Get the file extension
        const fileExtension: string = fileNameWithExtension.substring(
            dotIndex + 1
        );

        return { fileName, fileExtension };
    } else {
        // If there is no dot in the file name, assume there is no extension
        return { fileName: fileNameWithExtension, fileExtension: undefined };
    }
}
export const merge = <T>(args: T[][]): T[] =>
    args.reduce((prev, next) => prev.concat(next), [] as T[]);

export const formatFloatNumber = (num = 0, fixed = 0) => {
    if (isNaN(num) || !isFinite(num)) return 0;
    const regexPattern = /^-?[0-9]+$/;
    // check if the passed number is integer or float

    if (regexPattern.test(num.toString())) {
        return num;
    } else {
        if (typeof num === "number") return Number(num.toFixed(fixed));
    }

    return num;

    // check if the passed number is integer or float
};

export function paginator<T>(
    items: T[] | undefined = [],
    current_page: number,
    per_page_items: number
) {
    const page = current_page || 1,
        per_page = per_page_items,
        offset = (page - 1) * per_page,
        paginatedItems = items.slice(offset).slice(0, per_page_items),
        total_pages = Math.ceil(items.length / per_page);

    return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: total_pages > page ? page + 1 : null,
        total: items.length,
        total_pages: total_pages,
        data: paginatedItems,
    };
}

export const flattenTeams = (teams: Team[]): Array<Team | SubTeam> => {
    let temp: Array<Team | SubTeam> = [];

    for (let i = 0; i < teams.length; i++) {
        const e = teams[i];
        if (e?.subteams?.length) {
            temp = [...temp, ...e.subteams];
        } else {
            temp = [...temp, e];
        }
    }
    return temp;
};

export function numFormatter(num: number): number | string {
    num = Number(num);

    if (num > 999 && num < 1000000) {
        return (Math.floor(num / 100) / 10).toFixed(1) + "K"; //Math.floor(num / 100) / 10: This effectively removes all digits after the first decimal place without rounding, as it truncates the value.
    } else if (num > 1000000) {
        return (Math.floor(num / 100000) / 10).toFixed(1) + "M";
    }
    return num; // if value < 1000, nothing to do
}

export function ciPayloadWithPrimary(
    payload: CIFiltersPayload,
    is_primary: boolean
): CIFiltersPayload {
    return {
        ...payload,
        is_primary: is_primary || undefined,
    };
}

export const getDurationInSeconds = (
    start: string | Date = new Date(),
    end: string | Date = new Date()
): number => {
    return Math.abs(new Date(end).getTime() - new Date(start).getTime()) / 1000;
};

export const omitKeys = <
    T extends Record<string | number | symbol, unknown>,
    U extends Record<string, "">,
    V = Omit<T, keyof U>
>(
    obj: T,
    excludeObj: U
): V => {
    const excludeKeys = Object.keys(excludeObj);
    const returnObj = { ...obj };
    Object.keys(obj).forEach((key) => {
        if (excludeKeys.includes(key)) {
            delete returnObj[key];
        }
    });
    return returnObj as unknown as V;
};

export const appendLinkTags = (url: string): string => {
    const urlPattern =
        /(?:https?:\/\/|www\.)[\w-]+(\.[\w-]+)+[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]/g;

    return url.replace(urlPattern, (match) => {
        const prefixedMatch = match.startsWith("http")
            ? match
            : `https://${match}`;
        return `<a href="${prefixedMatch}" target="_blank">${match}</a>`;
    });
};

export const getDomainBaseLink = (): string => {
    const protocol = import.meta.env.VITE_APP_API_PROTOCOL || apiConfigs.HTTPS;
    const { hostname } = new URL(window.location.href);
    return !import.meta.env.PROD
        ? `http://${hostname}:3000`
        : `${protocol}${hostname}`;
};

export const getDomain = (): string => {
    const { hostname } = new URL(window.location.href);
    return hostname.split(".")?.[0] ?? "";
};
export async function blobToObject(blob: Blob): Promise<unknown> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
            try {
                const object = JSON.parse(reader.result as string);
                resolve(object);
            } catch (error) {
                reject(error);
            }
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsText(blob);
    });
}

export function getAuditConfigType(
    value: IParameterState["ai_audit_model"]
): string {
    switch (value) {
        case "expert":
            return "Expert";
        case "advance":
            return "Advance";
        case "na":
            return "Not Applicable";
        case "basic":
            return "Basic";
    }

    return "";
}

export function grpcPayloadConstructor(
    payload: Record<string, unknown>
): string {
    return Object.keys(payload)
        .reduce((acc, key) => {
            if (!isDefined(payload[key])) {
                return acc + "";
            }
            if (
                Array.isArray(payload[key]) &&
                (payload[key] as Array<unknown>).length
            )
                return (
                    acc +
                    `${key}=in:${(payload[key] as Array<unknown>).join(",")};`
                );
            if (payload[key] === true) {
                return acc + `${key};`;
            }
            return acc + `${key}=${payload[key]};`;
        }, "")
        .trim()
        .replace(/\s+/gm, "");
}

export function convertSecondsToTime(milliseconds: number): {
    hours: number;
    minutes: number;
    seconds: number;
} {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return {
        hours,
        minutes,
        seconds: remainingSeconds,
    };
}

export default function invariant(
    cond?: boolean,
    message?: string
): asserts cond {
    if (cond) {
        return;
    }

    throw new Error(
        "Internal Lexical error: invariant() is meant to be replaced at compile " +
            "time. There is no runtime version. Error: " +
            message
    );
}

export function replaceKeyInObjectsArray(
    objects: Array<Record<string, unknown>>,
    oldKey: string,
    newKey: string
): Array<Record<string, unknown>> {
    return objects.map((obj) => {
        if (oldKey in obj) {
            obj[newKey] = obj[oldKey];
            delete obj[oldKey];
        }
        return obj;
    });
}

export function replacePlaceholders(
    template: string,
    values: Record<string, string>
): string {
    return template.replace(
        /{{(.*?)}}/g,
        (match, key) => values[key.trim()] || match
    );
}

export const getMeetingTypeLabel = (value: MeetingTypeConst) => {
    return meetingTypes.find((type) => type.value === value)?.name || "";
};

export const objectKeyMap = (
    data: SearchFilter[]
): Record<FieldType, SearchFilter | never> => {
    const keyMapData = {} as Record<FieldType, SearchFilter | never>;
    for (const searchFilter of data) {
        keyMapData[searchFilter["name"]] = searchFilter;
    }
    return keyMapData;
};

export const exportAsImage = async (
    element: HTMLElement,
    imageFileName: string,
    backgroundColor: string = "white"
) => {
    const scale = 2;

    const style = {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        width: element.offsetWidth + "px",
        height: element.offsetHeight + "px",
    };

    const param = {
        height: element.offsetHeight * scale,
        width: element.offsetWidth * scale,
        quality: 1,
        bgcolor: backgroundColor,
        style,
    };

    domtoimage
        .toPng(element, param)
        .then(function (dataUrl: string) {
            downloadImage(dataUrl, imageFileName);
        })
        .catch(function (error: Error) {
            console.error("oops, something went wrong!", error);
        });
};

export const replaceSpaceWithHyphen = ({
    str,
    convertToSmallCase = false,
}: {
    str: string;
    convertToSmallCase?: boolean;
}) => {
    if (convertToSmallCase) {
        return str.replace(/\s+/g, "-").toLowerCase();
    } else {
        return str.replace(/\s+/g, "-");
    }
};
export const objectKeyArrayMap = (
    data: SearchFilter[]
): Record<FieldType, Array<SearchFilter | never>> => {
    const keyMapData = {} as Record<FieldType, Array<SearchFilter | never>>;
    for (const searchFilter of data) {
        keyMapData[searchFilter["name"]] = [
            ...(keyMapData[searchFilter["name"]] ?? []),
            searchFilter,
        ];
    }
    return keyMapData;
};

export function mergeContinuousSpeakers(
    speakers: Transcript[] = []
): Transcript[] {
    const mergedSpeakers: Transcript[] = [];
    try {
        for (const speaker of speakers) {
            let lastSpeaker = mergedSpeakers[mergedSpeakers.length - 1];

            // Check if the current speaker is the same as the last one in the merged array
            if (
                lastSpeaker &&
                lastSpeaker.speaker_id === speaker.speaker_id &&
                Math.abs(lastSpeaker.end_time - speaker.start_time) < 0.5
            ) {
                // Create a new object combining the last speaker and current speaker
                lastSpeaker = {
                    ...lastSpeaker,
                    end_time: speaker.end_time,
                    monologue_text:
                        lastSpeaker.monologue_text +
                        " " +
                        speaker.monologue_text,
                    // Merge other properties as needed
                };

                // For word alignment, handle it separately if defined
                if (
                    isDefined(lastSpeaker?.word_alignment) &&
                    isDefined(speaker?.word_alignment)
                ) {
                    lastSpeaker.word_alignment = [
                        ...lastSpeaker.word_alignment,
                        ...speaker.word_alignment,
                    ];
                }

                // Replace the last speaker with the updated one
                mergedSpeakers[mergedSpeakers.length - 1] = lastSpeaker;
            } else {
                // Add the current speaker as a new entry
                mergedSpeakers.push({ ...speaker });
            }
        }
    } catch (err) {
        console.error(err);
    }

    return mergedSpeakers;
}
export function generateTopicsFromTranscript(
    speakers: Transcript[] = []
): IConversationDetails["topics"] {
    const topicObj: IConversationDetails["topics"] = {};
    for (const {
        topics,
        start_time,
        end_time,
        monologue_text,
        speaker_type,
        speaker_name,
    } of speakers) {
        if (Object.keys(topics).length) {
            for (const topic of Object.keys(topics)) {
                const color = getColorFromString(topic);
                if (!topicObj[topic]) {
                    topicObj[topic] = {
                        color,
                        phrases: {},
                        transcript: [
                            {
                                start_time,
                                end_time,
                                monologue_text,
                                color,
                                speaker_type,
                                speaker_name,
                            },
                        ],
                    };
                } else {
                    topicObj[topic] = {
                        ...topicObj[topic],
                        transcript: [
                            ...topicObj[topic].transcript,
                            {
                                start_time,
                                end_time,
                                monologue_text,
                                color,
                                speaker_type,
                                speaker_name,
                            },
                        ],
                    };
                }
                const phrases = topics[topic].map((e) => e.detected_phrases);

                for (const phrase of phrases) {
                    for (const uniquePhrase of Object.keys(phrase)) {
                        if (!isDefined(topicObj[topic].phrases[uniquePhrase]))
                            topicObj[topic].phrases[uniquePhrase] = {
                                count: 1,
                                transcript: [
                                    {
                                        start_time,
                                        end_time,
                                        monologue_text: phrase[uniquePhrase],
                                        speaker_type,
                                        speaker_name,
                                    },
                                ],
                            };
                        else {
                            topicObj[topic].phrases[uniquePhrase] = {
                                count:
                                    topicObj[topic].phrases[uniquePhrase]
                                        .count + 1,
                                transcript: [
                                    ...topicObj[topic].phrases[uniquePhrase]
                                        .transcript,
                                    {
                                        start_time,
                                        end_time,
                                        monologue_text: phrase[uniquePhrase],
                                        speaker_type,
                                        speaker_name,
                                    },
                                ],
                            };
                        }
                    }
                }
            }
        }
    }

    return topicObj;
}

export function formatTranscriptBasedOnSpeakers(
    speakers: Transcript[] = []
): Record<string, Transcript[]> {
    const obj: Record<string, Transcript[]> = {};
    speakers?.forEach((speaker) => {
        if (!isDefined(obj[speaker.speaker_id])) {
            obj[speaker.speaker_id] = [];
        }
        obj[speaker.speaker_id].push(speaker);
    });
    return obj;
}

export function getQuestionsAndActionItemsFromTranscript(
    transcripts: Transcript[] = []
): Pick<IConversationDetails, "questions" | "actionItems"> {
    const questions: IConversationDetails["questions"] =
        {} as IConversationDetails["questions"];
    const actionItems: IConversationDetails["actionItems"] =
        {} as IConversationDetails["questions"];
    for (const {
        sentence_categories,
        speaker_name,
        speaker_type,
        speaker_id,
    } of transcripts) {
        const question = sentence_categories["question"];
        if (sentence_categories["question"]?.length) {
            if (!questions[speaker_id]) {
                questions[speaker_id] = {
                    speaker_id,
                    speaker_name,
                    speaker_type,
                    transcripts: question.map((q) => ({
                        ...q,
                        speaker_type,
                        speaker_id,
                        speaker_name,
                    })),
                };
            } else {
                questions[speaker_id] = {
                    ...questions[speaker_id],
                    transcripts: [
                        ...questions[speaker_id].transcripts,
                        ...question.map((q) => ({
                            ...q,
                            speaker_type,
                            speaker_id,
                            speaker_name,
                        })),
                    ],
                };
            }
        }
        const action = sentence_categories["action"];
        if (action?.length) {
            if (!actionItems[speaker_id]) {
                actionItems[speaker_id] = {
                    speaker_id,
                    speaker_name,
                    speaker_type,
                    transcripts: action.map((a) => ({
                        ...a,
                        speaker_type,
                        speaker_id,
                        speaker_name,
                    })),
                };
            } else {
                actionItems[speaker_id] = {
                    ...actionItems[speaker_id],
                    transcripts: [
                        ...actionItems[speaker_id].transcripts,
                        ...action.map((a) => ({
                            ...a,
                            speaker_type,
                            speaker_id,
                            speaker_name,
                        })),
                    ],
                };
            }
        }
    }

    return {
        questions,
        actionItems,
    };
}

export function getConversationSpeakerInfo(
    speakers: Transcript[] = []
): Record<string, { name: string; speakerType: string }> {
    const obj: Record<string, { name: string; speakerType: string }> = {};
    speakers?.forEach((speaker) => {
        if (!isDefined(obj[speaker.speaker_id])) {
            obj[speaker.speaker_id] = {
                name: speaker.speaker_name,
                speakerType: speaker.speaker_type,
            };
        }
    });
    return obj;
}

export const generateSentimentMonologue = (
    snippets: ConversationDetails["stats"]["sentiment_snippets"] | undefined
) => {
    if (!isDefined(snippets)) {
        return {};
    }

    const { negative = [], positive = [] } = snippets;

    const sentimentMonologues = {} as Record<
        string,
        Array<SentimentTranscript>
    >;
    for (const transcript of [
        ...negative.map((e) => ({ ...e, sentiment_score: -1 })),
        ...positive.map((e) => ({ ...e, sentiment_score: 1 })),
    ]) {
        if (!isDefined(sentimentMonologues?.[transcript.speaker_name])) {
            sentimentMonologues[transcript.speaker_name] = [];
        }
        sentimentMonologues[transcript?.speaker_name]?.push({
            start_time: transcript.start_time,
            end_time: transcript.end_time,
            speaker_name: transcript.speaker_name,
            speaker_type: transcript.speaker_type,
            sentiment:
                transcript?.sentiment_score > 0 ? "positive" : "negative",
        });
    }

    return sentimentMonologues;
};

export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}

export function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")?.[0]?.[0]}`,
    };
}

const flattenDataInObject = (
    data: null | Record<string, unknown>,
    newObj: Record<string, unknown>
) => {
    if (!isDefined(data)) return {};
    if (typeof data == "object" && Object.keys(data).length) {
        Object.keys(data).forEach((e) => {
            if (typeof data[e] != "object") {
                newObj[e] = data[e];
            }
            if (
                Array.isArray(data[e]) &&
                (typeof (data[e] as Array<string>)?.[0] == "string" ||
                    typeof (data[e] as Array<number>)?.[0] == "number")
            ) {
                newObj[e] = (data[e] as Array<string | number>).join(", ");
            }
            if (
                typeof data[e] == "object" &&
                !(
                    typeof (data[e] as Array<string>)?.[0] == "string" ||
                    typeof (data[e] as Array<string>)?.[0] == "number"
                )
            ) {
                flattenDataInObject(data[e] as Record<string, unknown>, newObj);
            }
        });
    }
};

export const flattenObject = (
    data: Record<string, unknown>
): Record<string, string> => {
    const myObj = {};
    flattenDataInObject(data, myObj);
    return myObj;
};

export const getScore = (
    args: AuditStatus<ManualScore | AIScore>["scores"] | undefined | null
): [string | null, number | null] => {
    if (!isDefined(args)) return [null, null];
    const { template_marks_audited, template_score } = args;
    return [
        `${formatFloatNumber(template_score, 2)} / ${formatFloatNumber(
            template_marks_audited,
            1
        )}`,
        formatFloatNumber((template_score / template_marks_audited) * 100, 1),
    ];
};

export const getManualDraftScore = (
    args: AuditStatus<ManualScore>["scores"] | undefined | null
): [string | null, number | null] => {
    if (!isDefined(args)) return [null, null];
    const { draft_template_marks_audited, draft_template_score } = args;
    return [
        `${formatFloatNumber(draft_template_score, 1)}/${formatFloatNumber(
            draft_template_marks_audited,
            1
        )}`,
        formatFloatNumber(
            (draft_template_score / draft_template_marks_audited) * 100,
            1
        ),
    ];
};

export const getParameterMaxWeight: (
    question: Pick<Question, "question_type" | "settings">
) => number | null = ({
    question_type,
    settings,
}: Pick<Question, "question_type" | "settings">) => {
    switch (question_type) {
        case "rating":
            return settings.weight;
        case "custom":
            return Math.max(...settings.custom.map((e) => e.weight));
        case "yes_no":
            return Math.max(settings.yes_weight || 0, settings.no_weight || 0);
        default:
            return null;
    }
};

export function splitText(text: string, query: string): string[] {
    if (!query) return [text];

    const regex = new RegExp(`(${query})`, "gi");
    return text.split(regex);
}

export function findImageUrl(text: string): string | null {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|svg)$/i;

    const urls = text.match(urlRegex);
    if (urls) {
        for (const url of urls) {
            if (imageUrlRegex.test(url)) {
                return url;
            }
        }
    }
    return null;
}

export function scrollElementIntoView(selector: string): void {
    const element = document.querySelector(selector);
    if (element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    } else {
        console.error(`Element with selector '${selector}' not found.`);
    }
}

export function scrollDashboardIntoView(ref: HTMLDivElement): void {
    if (ref) {
        ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    } else {
        console.error("Dashboard ref is not defined");
    }
}

export const groupByDate = <T extends Record<U, unknown>, U extends keyof T>(
    data: T[],
    dateKey: U
): Array<T & { firstofDay?: boolean }> => {
    let firstOfKey: string | null = null;
    return [...data].map((item) => {
        if (firstOfKey === new Date(item[dateKey] as string).toDateString()) {
            return item;
        } else {
            firstOfKey = new Date(item[dateKey] as string).toDateString();
            return { ...item, firstOfDay: true };
        }
    });
};

export const getNotificationId = (value: string): string => {
    if (!isDefined(value)) return "Immediately";
    for (const key in NotificationSettings) {
        if (isEqual(NotificationSettings[key], JSON.parse(value))) {
            return key;
        }
    }
    return "Immediately";
};

export const generateRandomNumber = ({
    min,
    max,
}: {
    min: number;
    max: number;
}) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getMinMaxLabel = ({
    min,
    max,
    unit = "",
}: {
    min: number | null;
    max: number | null;
    unit?: string;
}): string => {
    return isDefined(min) && isDefined(max)
        ? `Between ${min} - ${max} ${unit}`
        : isDefined(min)
        ? `Above ${min} ${unit}`
        : isDefined(max)
        ? `Below ${max} ${unit}`
        : "";
};

export const extractTranscriptURL = (url?: string) => {
    if (!url) {
        return null;
    }
    const regex = /transcripts\/[0-9]+\/[0-9-]+_transcripts\.json/g;
    const match = url.match(regex);
    if (match && match.length > 0) {
        return match[0];
    } else {
        return null;
    }
};

export const conversationScoreMapping = (
    option: ConversationScoreType | null,
    thresholds: DomainConfig["stats_threshold"] | null
): NumericRange => {
    const scoreThresholds = thresholds ?? defaultScoreThresholds;

    switch (option) {
        case ConversationScoreType.good:
            return [scoreThresholds?.good, null];
        case ConversationScoreType.average:
            return [scoreThresholds?.average, scoreThresholds?.good - 1];
        case ConversationScoreType.bad:
            return [null, scoreThresholds?.bad - 1];
        default:
            return [null, null];
    }
};

export const getParameterResponseOptions = (
    e: Question | ParameterStateType | undefined
): Array<{ value: string; id: number | string }> => {
    if (!isDefined(e)) return [];
    if (e.question_type === "yes_no") {
        return [
            { id: 1, value: "Yes" },
            { id: 0, value: "No" },
            { id: -1, value: "NA" },
        ];
    }
    if (e.question_type === "rating") {
        return [
            ...new Array(11)
                .fill(0)
                .map((_, idx) => ({ id: idx, value: idx.toString() })),
            { id: -1, value: "NA" },
        ];
    }
    if (e.question_type === "custom") {
        const naOption = e.settings.custom.find(
            (e) => e.name.toLowerCase() === "na"
        );
        const customOptions = e.settings.custom
            .map((op) => (op.name.toLowerCase() === "na" ? undefined : op))
            .filter(Boolean);

        return [...customOptions, isDefined(naOption) && naOption]
            .filter(Boolean)
            .map((e) => ({ id: e.id, value: e.name }))
            .filter((e) => typeof e.id !== "string");
    }
    return [];
};
export const extractFileType = (mediaUrl: string): string => {
    const tempArr = mediaUrl.split("?")?.[0]?.split(".");
    if (tempArr) return tempArr[tempArr.length - 1];
    else return "";
};

export const checkFileType = (
    file_type: string
): "audio" | "video" | "document" => {
    if (file_type === null) return "video";
    const audio = {
        mp3: "mp3",
        ogg: "ogg",
        amr: "amr",
        au: "au",
        awb: "awb",
        flac: "flac",
        mid: "mid",
        wav: "wav",
        wma: "wma",
        mka: "mka",
        webm: "webm",
        acc: "acc",
        ac3: "ac3",
        aiff: "aiff",
        "3gpp": "3gpp",
        smf: "smf",
        aac: "aac",
        "amr-wb": "amr-wb",
        "x-amr-wb": "x-amr-wb",
    };
    const video = {
        mp4: "mp4",
        wav: "wav",
        mov: "mov",
        wmv: "wmv",
        avi: "avi",
        flv: "flv",
        mpg: "mpg",
        mpeg: "mpeg",
        avchd: "avchd",
        f4v: "f4v",
        swf: "swf",
        mkv: "mkv",
        webm: "webm",
        m4a: "m4a",
    };
    if (file_type in audio) {
        return "audio";
    } else if (file_type in video) {
        return "video";
    } else return "document";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateDownloadQue: (params: {
    data: DownloadReport;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<any, any, AnyAction>;
    message?: string;
}) => void = ({
    data,
    dispatch,
    message = "Your report is generating. You will be notified once it is ready.",
}) => {
    showToast({
        message,
        ...CreateUpdateToastSettings,
    });
    dispatch(
        analyticsDashboardApiSlice.util.updateQueryData(
            "getReportsDownloadQue",
            {},
            (draft) => {
                return {
                    ...draft,
                    count: draft.count + 1,
                    results: [data, ...draft.results],
                };
            }
        )
    );
};

export const getQmsFieldsForBulkOpload = (
    e: QmsField[] | undefined
): Array<Pick<QmsField, "name" | "type" | "is_mandatory">> => {
    if (!isDefined(e)) return [];
    return [
        ...e.filter((e) => !e.is_disabled),
        {
            name: "Agent Team",
            type: "owner_team",
            is_mandatory: false,
        },
        {
            name: "Agent First Name",
            type: "owner_first_name",
            is_mandatory: false,
        },
        {
            name: "Agent Last Name",
            type: "owner_last_name",
            is_mandatory: false,
        },
        {
            name: "User Id",
            type: "owner_primary_phone",
            is_mandatory: false,
        },
    ];
};

export const highlightSearchQuery = (htmlString: string, query: string) => {
    if (!query) return htmlString;

    const escapeRegExp = (string: string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const escapedQuery = escapeRegExp(query);

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const walkAndHighlight = (node: Node) => {
        if (node.nodeType === 3) {
            // text node
            const regex = new RegExp(`(${escapedQuery})`, "gi");
            if (node.nodeValue) {
                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = node.nodeValue.replace(regex, "⚑$1⚑");

                while (tempDiv.firstChild) {
                    node.parentNode!.insertBefore(tempDiv.firstChild, node);
                }
                node.parentNode!.removeChild(node);
            }
        } else if (node.nodeType === 1 && node.nodeName !== "SCRIPT") {
            // element node
            Array.from(node.childNodes).forEach(walkAndHighlight);
        }
    };

    walkAndHighlight(doc.body);

    return doc.body.innerHTML.replace(/⚑(.*?)⚑/g, "<mark>$1</mark>");
};

export const isDarkMode = (theme: Theme): boolean => {
    return theme.palette.mode === "dark";
};

type JsonValue =
    | string
    | number
    | boolean
    | null
    | undefined
    | JsonObject
    | JsonArray;
type JsonObject = { [key: string]: JsonValue };
type JsonArray = JsonValue[];

export function jsonToFormData(json: JsonObject): FormData {
    const formData = new FormData();

    function appendToFormData(data: JsonValue, parentKey: string = ""): void {
        if (data === undefined) {
            // Ignore undefined values
            return;
        }

        if (
            data &&
            typeof data === "object" &&
            !(data instanceof Date) &&
            !(data instanceof File)
        ) {
            Object.entries(data).forEach(([key, value]) => {
                const newKey = parentKey ? `${parentKey}[${key}]` : key;
                appendToFormData(value, newKey);
            });
        } else if (Array.isArray(data)) {
            data.forEach((item, index) => {
                const newKey = `${parentKey}[${index}]`;
                appendToFormData(item, newKey);
            });
        } else {
            const value = data === null ? "" : data.toString();
            formData.append(parentKey, value);
        }
    }

    appendToFormData(json);
    return formData;
}

export function dropDownActiveClass(active: boolean) {
    return active ? "active dropdown-item-active" : "";
}

export const errorReload = (error: string, retries = 1) => {
    console.error(error);

    const errRetries: string | null = sessionStorage.getItem("errRetries");
    console.warn("errorReload()", typeof errRetries, errRetries);
    if (typeof errRetries === "string") {
        // we have tried before
        if (parseInt(errRetries) >= retries) {
            // ah, give up
            console.warn("errorReload() now give up");
            return;
        }

        console.warn("errorReload() now try again");
        sessionStorage.setItem("errRetries", String(parseInt(errRetries) + 1));
    } else {
        // we have not tried before
        console.warn("errorReload() try again");
        sessionStorage.setItem("errRetries", "1");
    }

    window.location.reload();
};
